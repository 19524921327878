import React, { useState } from "react";
import styled from "styled-components";
import media from "../styles/Breakpoints";
import { animated, useSpring } from "react-spring";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  const validEmail = new RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  const up = useSpring({
    from: {
      transform: "translate(0, 100%)"
    },
    transform: "translate(0, 0)",
    delay: 250
  });

  const up2 = useSpring({
    from: {
      transform: "translate(0, 100%)"
    },
    transform: "translate(0, 0)",
    delay: 350
  });

  const up3 = useSpring({
    from: {
      transform: "translate(0, 100%)"
    },
    transform: "translate(0, 0)",
    delay: 350
  });

  const up4 = useSpring({
    from: {
      transform: "translate(0, 100%)"
    },
    transform: "translate(0, 0)",
    delay: 400
  });

  const up5 = useSpring({
    from: {
      transform: "translate(0, 100%)"
    },
    transform: "translate(0, 0)",
    delay: 450
  });

  const up6 = useSpring({
    from: {
      transform: "translate(0, 100%)"
    },
    transform: "translate(0, 0)",
    delay: 500
  });

  const sendRequest = async () => {
    if (name === "" || message === "") {
      setError("Invalid input");
      return false;
    }

    if (!validEmail.test(email)) {
      setError("Invalid email");
      return false;
    }

    await fetch(`https://dark-server.herokuapp.com/api/send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        to: "pb083106@gmail.com",
        // name,
        // email,
        text: `${name} <${email}>`,
        message
      })
    })
      .then(res => res.text())
      .then(async res => {
        if (res === "sent") {
          setError("");
          setName("");
          setEmail("");
          setMessage("");
          setStatus("Sent!");
        } else {
          setError("Unable to send at this moment.");
        }
      })
      .catch(err => console.error(err));
  };

  return (
    <ContactContainer>
      <Helmet>
        <title>Contact</title>
        <meta name="description" content="Contact page for Paolo Burgos" />
      </Helmet>
      <ContactContent>
        <ContactGreeting style={up}>
          Let's work together!
          <span role="img" aria-label="peace sign">
            ✌
          </span>
        </ContactGreeting>
        <ContactLine style={up2} />
        <Error>{error}</Error>
        <Status>{status}</Status>
        <ContactForm>
          <FieldGroup style={up3}>
            <Input
              name="name"
              value={name}
              onChange={e => setName(e.target.value)}
              autoComplete="off"
              required="required"
            />
            <span className="highlight"></span>
            <Label>Name</Label>
            <Bar />
          </FieldGroup>

          <FieldGroup style={up4}>
            <Input
              name="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              autoComplete="off"
              required="required"
            />
            <span className="highlight"></span>
            <Bar />
            <Label>Email</Label>
          </FieldGroup>
          <FieldGroup style={up5}>
            <TextArea
              name="message"
              value={message}
              onChange={e => setMessage(e.target.value)}
              required="required"
            />
            <span className="highlight"></span>
            <Bar />
            <Label>Message</Label>
          </FieldGroup>

          <Submit className="sd-gFqdaJ" onClick={sendRequest} style={up6}>
            Send Request
          </Submit>
        </ContactForm>
      </ContactContent>
    </ContactContainer>
  );
};

const ContactContainer = styled.div`
  background: ${props => props.theme.left};
  width: 100%;
  height: 100vh;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-content: center;
  overflow-y: auto;
  ${media.tabletLand`
  align-content: unset;
  `}
`;

const ContactContent = styled.div`
  width: 100%;
  max-width: 340px;
  ${media.tabletLand`
    padding: 5rem 0;
    width: 400px;
    margin: auto;
  `}
  ${media.mobileLand`
    padding: 3rem 0;
    width: 400px;
    margin: auto;
  `}
  ${media.mobile`
    width: 100%;
    padding: 3rem 1.5rem;
  `}
`;

const ContactGreeting = styled(animated.div)`
  font-size: 2rem;
  color: #fff;
  ${media.mobile`
  font-size: 1.5rem;
  `}
`;

const ContactLine = styled(animated.div)`
  width: 100%;
  height: 1.5rem;
  border-bottom: 1px solid #e0e0e0;
  margin: 0 0 0.5rem 0;
`;

const Error = styled.div`
  color: #fff;
  background: ${props => props.theme.secondary};
  font-size: 1rem;
  text-align: center;
  transition: 300ms ease all;
  /* padding: 0.5rem; */
`;

const Status = styled.div`
  color: #fff;
  background: #37c5ab;
  font-size: 1rem;
  text-align: center;
  transition: 300ms ease all;
`;

const ContactForm = styled.form`
  width: 100%;
`;

const FieldGroup = styled(animated.div)`
  position: relative;
  margin: 45px 0;
`;

const Label = styled.label`
  color: ${props => props.theme.text};
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  left: 5px;
  top: 10px;
  transition: 300ms ease all;
`;

const Bar = styled.span`
  position: relative;
  display: block;
  width: 100%;
  &::before {
    content: "";
    height: 2px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background: ${props => props.theme.secondary};
    transition: 300ms ease all;
    left: 0%;
  }
`;

const Input = styled.input`
  background: none;
  color: #c6c6c6;
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #c6c6c6;

  &:focus {
    outline: none;
  }
  &:focus ~ ${Label}, &:valid ~ ${Label} {
    top: -14px;
    font-size: 12px;
    color: ${props => props.theme.secondary};
  }
  &:focus ~ ${Bar}::before {
    width: 100%;
  }
`;

const TextArea = styled.textarea`
  resize: none;
  background: none;
  color: #c6c6c6;
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  height: 42px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #c6c6c6;
  &:focus {
    background-size: 100% 2px, 100% 1px;
    outline: none;
  }
  &:focus ~ ${Label}, &:valid ~ ${Label} {
    top: -14px;
    font-size: 12px;
    color: ${props => props.theme.secondary};
  }
  &:focus ~ ${Bar}:before {
    width: 100%;
  }
`;

const Submit = styled(animated.div)`
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1.5;
  padding: 1em 2em;
  letter-spacing: 0.05rem;
  text-align: center;
  width: 200px;

  &:focus {
    outline: ${props => `2px dotted ${props.theme.secondary}`};
  }

  box-shadow: ${props => `inset 0 0 0 3px ${props.theme.text}`};
  color: ${props => props.theme.text};
  transition: color 0.25s 0.0833333333s;
  position: relative;

  &::before,
  &::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: "";
    pointer-events: none;
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0;
    right: 0;
  }
  &::before {
    border-bottom-width: 4px;
    border-left-width: 4px;
  }
  &::after {
    border-top-width: 4px;
    border-right-width: 4px;
  }
  &:hover {
    color: ${props => props.theme.secondary};
  }
  &:hover::before,
  &:hover::after {
    border-color: ${props => props.theme.secondary};
    transition: border-color 0s, width 0.25s, height 0.25s;
    width: 100%;
    height: 100%;
  }
  &:hover::before {
    transition-delay: 0s, 0s, 0.25s;
  }
  &:hover::after {
    transition-delay: 0s, 0.25s, 0s;
  }
`;

export default Contact;
