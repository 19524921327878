import React from "react";
import Marquee from "./Marquee";
import { Link } from "react-router-dom";
import styled from "styled-components";
import media from "../styles/Breakpoints";

const Home = () => {
  return (
    <HomeContainer>
      <HomeContent>
        <Marquee />
        <Arrow>
          <AnimatedArrow to="/about">
            <span className="the-arrow -left">
              <span className="shaft"></span>
            </span>
            <span className="main">
              <span className="text">Developer/Human</span>
              <span className="the-arrow -right">
                <span className="shaft"></span>
              </span>
            </span>
          </AnimatedArrow>
        </Arrow>
      </HomeContent>
    </HomeContainer>
  );
};

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
const HomeContent = styled.div`
  margin: 3rem;
`;

const Arrow = styled.div`
  margin-top: 0;
  ${media.mobile`
   `}
`;

const AnimatedArrow = styled(Link)`
  display: inline-block;
  color: #999;
  font-size: 1.25em;
  font-style: italic;
  text-decoration: none;
  position: relative;
  transition: all 0.2s;
  &:hover {
    color: #eaeaea;
    & > .the-arrow.-left > .shaft {
      width: 64px;
      transition-delay: 0.1s;
      background-color: #eaeaea;
    }
    & > .the-arrow.-left > .shaft::before,
    .the-arrow.-left > .shaft::after {
      width: 8px;
      transition-delay: 0.1s;
      background-color: #eaeaea;
    }
    & > .the-arrow.-left > .shaft:before {
      transform: rotate(40deg);
    }
    & > .the-arrow.-left > .shaft:after {
      transform: rotate(-40deg);
    }
    & > .main {
      transform: translateX(80px);
    }
    & > .main > .the-arrow.-right > .shaft {
      width: 0;
      transform: translateX(200%);
      transition-delay: 0;
    }
    & > .main > .the-arrow.-right > .shaft::before,
    .main > .the-arrow.-right > .shaft::after {
      width: 0;
      transition-delay: 0;
      transition: all 0.1s;
    }
    & > .main > .the-arrow.-right > .shaft:before {
      transform: rotate(0);
    }
    & > .main > .the-arrow.-right > .shaft:after {
      transform: rotate(0);
    }
  }
  & > .main {
    display: flex;
    align-items: center;
    transition: all 0.2s;
    & > .text {
      margin: 0 16px 0 0;
      line-height: 1;
      /* color: var(--gold); */
      color: ${props => props.theme.secondary};
    }
    & > .the-arrow {
      position: relative;
    }
  }
  ${media.mobile`
    font-size: 1rem;
  `}
`;

export default Home;
