import React from 'react';
import DynamicBurger from '../utils/DynamicBurger';
import styled from 'styled-components';
import media from '../styles/Breakpoints';

const MenuToggle = () => {
  return (
    <MToggle>
      <DynamicBurger />
    </MToggle>
  );
};

const MToggle = styled.menu`
  position: absolute;
  top: 0;
  right: 0;
  margin: 3rem;
  z-index: 999;
  ${media.tabletLand`
    margin: 1rem;
  `}
  ${media.mobileLand`
    margin: 1rem;
  `}
  ${media.mobile`
    margin: 1rem;
   `}
`;

export default MenuToggle;
