import React, { useState, useCallback } from "react";
import projects from "./ProjectDetails";
import { useTrail, animated, useSpring } from "react-spring";
import Overlay from "../utils/Overlay";
import styled from "styled-components";
import media from "../styles/Breakpoints";
import { Helmet } from "react-helmet";

const Projects = () => {
  const [overlay, setOverlay] = useState(false);
  const [currCard, setCurrCard] = useState(null);

  const slide = useSpring({
    from: {
      transform: "translate(100%, 0)"
    },
    transform: "translate(0, 0)"
  });

  const clickOverlay = useCallback(
    e => {
      setOverlay(!overlay);
      setCurrCard([e]);
    },
    [overlay]
  );

  const closeOverlay = useCallback(_ => {
    setOverlay(false);
    setCurrCard([]);
  }, []);

  const cardTrail = useTrail(projects.length, {
    from: { marginLeft: -20, opacity: 0, transform: "translate3d(0,-40px,0)" },
    to: { marginLeft: 0, opacity: 1, transform: "translate3d(0,0px,0)" },
    delay: 500
  });

  return (
    <>
      <ProjectSlide style={slide}>
        <Helmet>
          <title>Projects</title>
          <meta name='description' content='Projects page for Paolo Burgos' />
        </Helmet>
        <ProjectHeader>
          Projects
          <hr />
        </ProjectHeader>

        <ProjectContainer>
          {cardTrail.map((props, index) => (
            <ProjectImage
              key={projects[index].id}
              style={{ ...props }}
              onClick={() => clickOverlay(projects[index])}
            >
              <img
                src={projects[index].img}
                alt=''
                style={projects[index].imgStyle}
                draggable={false}
              />
              <Caption>
                <h3>{projects[index].name}</h3>
                <p>{projects[index].tag}</p>
              </Caption>
            </ProjectImage>
          ))}
        </ProjectContainer>
        <ProjectFooter>
          Made with <span>❤</span> by Paolo Burgos
        </ProjectFooter>
      </ProjectSlide>
      <Overlay currCard={currCard} closeOverlay={closeOverlay} />
    </>
  );
};

const ProjectSlide = styled(animated.div)`
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  background: ${props => props.theme.right};
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
`;

const ProjectHeader = styled.div`
  font-size: 2rem;
  margin: 7rem auto 2rem 0;
  width: 100%;
  color: #fff;
  text-align: center;
  display: none;
  & hr {
    width: 20px;
    margin: 1rem auto;
  }
  ${media.mobile`
  display: block;
  `}
`;

const ProjectContainer = styled.div`
  padding: 1rem;
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-content: center;
  grid-gap: 1rem;
  ${media.laptopLarge`
    max-width: 800px;
    grid-template-columns: repeat(3, 1fr);
    padding: 5rem 1rem;
  `}
  ${media.tabletLand`
    max-width: 600px;
    grid-template-columns: repeat(3, 1fr);
    height: 100%;
  `}
  ${media.tablet`
    max-width: 600px;
    grid-template-columns: repeat(3, 1fr);
    height: 100%;
  `}
  ${media.mobile`
    grid-template-columns: repeat(2, 1fr);
  `}
`;

const Caption = styled.figcaption`
  width: 100%;
  color: #fff;
  margin: 0 1rem;
  text-align: left;
  text-decoration: none;
  position: absolute;
  bottom: 5%;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  & p {
    color: ${props => props.theme.text};
    font-size: 14px;
  }
  ${media.mobile`
    margin: 0 .5rem;
    & h3 {
    font-size: 14px;

    }
    & p {
      font-size: 10px;
    }
  `}
`;

const ProjectImage = styled(animated.figure)`
  cursor: pointer;
  height: 100%;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    -webkit-transition: opacity 0.35s ease;
    -moz-transition: opacity 0.35s ease;
    -o-transition: opacity 0.35s ease;
    transition: opacity 0.35s ease;
  }

  &:hover img {
    opacity: 0.2;
  }

  &:hover ${Caption} {
    bottom: 10%;
    opacity: 1;
  }
`;

const ProjectFooter = styled.div`
  margin: 2rem auto;
  color: #545454;
  text-align: center;
  font-size: 0.7rem;
  display: none;
  & span {
    color: red;
  }
  ${media.mobile`
    display: block;
  `}
`;

export default Projects;
