import React from "react";
import styled from "styled-components";
import PortContext from "../../Contexts/PortContext";
import ThemesData from "../styles/Themes";
import media from "../styles/Breakpoints";

const Palette = () => {
  return (
    <PortContext.Consumer>
      {({ isPalette, handleTheme }) => (
        <Themes isPalette={isPalette}>
          <PaletteContainer>
            {Object.values(ThemesData).map(item => (
              <ThemeItem
                key={item.id}
                l={item.left}
                r={item.right}
                onClick={() => handleTheme(item.id)}
              >
                <div></div>
                <div></div>
                <ThemeTitle>{item.name}</ThemeTitle>
              </ThemeItem>
            ))}
          </PaletteContainer>
        </Themes>
      )}
    </PortContext.Consumer>
  );
};

const Themes = styled.div`
  width: 100%;
  background: ${props => props.theme.right};
  /* background: #f6f6f6; */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #fff;
  z-index: 30;
  height: ${props => (props.isPalette ? "100vh" : "0vh")};
  opacity: ${props => (props.isPalette ? 1 : 0)};
  visibility: ${props => (props.isPalette ? "visible" : "hidden")};
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  will-change: opacity, height, width;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PaletteContainer = styled.div`
  /* height: 300px; */
  width: 100%;
  max-width: 500px;
  /* background: #25262b; */
  background: #fff;
  box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
  border-radius: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  padding: 1rem;
  justify-items: center;
  align-items: center;
  text-align: center;
  ${media.mobile`
    grid-template-columns: repeat(2, 1fr);
    margin: 2rem;
  `}
`;

const ThemeItem = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  /* border: 2px solid white; */
  overflow: hidden;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  cursor: pointer;
  transition: box-shadow 0.25s ease, transform 0.25s ease;

  & div:nth-child(1) {
    height: 100%;
    width: 100%;
    margin-right: auto;
    background: ${props => props.l};
  }
  & div:nth-child(2) {
    height: 100%;
    width: 100%;
    margin-left: auto;
    background: ${props => props.r};
  }

  &:hover {
    box-shadow: 5px 12px 20px rgba(36, 37, 38, 0.13);
    transform: scale(1.1);
    z-index: 5;
  }
`;

const ThemeTitle = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export default Palette;
