import React, { useState, useContext, useEffect, useCallback } from "react";
import { Route, Switch, __RouterContext } from "react-router-dom";
import { useTransition, animated } from "react-spring";
import { Helmet } from "react-helmet";
import "./App.css";
import PortContext from "./Contexts/PortContext";
import Home from "./components/Home/Home";
import Projects from "./components/Projects/Projects";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import NavLinks from "./components/layouts/NavLinks";
import Snow from "./components/layouts/Snow";
import SideLinks from "./components/layouts/SideLinks";
import MenuToggle from "./components/layouts/MenuToggle";
import styled, { ThemeProvider } from "styled-components";
import Container from "./components/styles/Container";
import Palette from "./components/utils/Palette";
import Themes from "./components/styles/Themes";

const App = () => {
  const { location } = useContext(__RouterContext);
  const [isMenu, setMenu] = useState(false);
  const [isParticles, setParticles] = useState(true);
  const [isPalette, setPalette] = useState(false);
  const [currTheme, setTheme] = useState("dark");

  // Handle palette show
  const handlePalette = useCallback(() => setPalette(!isPalette), [isPalette]);

  // No state toggle. Explicit calls are needed for reasons.
  const handleClose = useCallback(() => {
    setPalette(false);
    setMenu(false);
  }, []);
  const handleOpen = useCallback(() => setMenu(true), []);

  // Handle localStorage and state management for particles
  const handleParticles = useCallback(() => setParticles(!isParticles), [
    isParticles
  ]);

  const handleTheme = useCallback(i => setTheme(i), []);

  useEffect(() => {
    const particles = localStorage.getItem("particles-stat");
    const storedTheme = localStorage.getItem("active-theme");
    if (particles) {
      setParticles(JSON.parse(particles));
    }

    if (storedTheme) {
      setTheme(JSON.parse(storedTheme));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("particles-stat", JSON.stringify(isParticles));
    localStorage.setItem("active-theme", JSON.stringify(currTheme));
  });

  // const locationName = location.pathname.replace('/', '');
  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 0, transform: "translate(-100%, 0)" },
    enter: { opacity: 1, transform: "translate(0%, 0)" },
    leave: { opacity: 0, transform: "translate(-50%, 0)" }
  });

  // Determine active theme
  let activeTheme = Object.values(Themes).filter(
    theme => theme.id === currTheme
  )[0];

  return (
    <PortContext.Provider
      value={{
        isMenu,
        handleClose,
        handleOpen,
        handleParticles,
        handlePalette,
        isPalette,
        handleTheme
      }}
    >
      <ThemeProvider theme={activeTheme}>
        <Container>
          <Helmet
            titleTemplate='%s - Paolo Burgos ✌️'
            defaultTitle='Paolo Burgos ✌️'
          >
            <meta charSet='utf-8' />
            <meta
              name='description'
              content='Paolo Burgos Portfolio created using React'
            />
          </Helmet>
          <Palette />
          <SplitLeft />
          <SplitRight />
          <MenuToggle />
          <NavLinks handleClose={handleClose} />
          <SideLinks />
          {isParticles ? <Snow /> : null}

          {transitions.map(({ item, props, key }) => (
            <animated.div key={key} style={{ ...props, zIndex: 5 }}>
              <Switch location={item}>
                <Route exact path='/' component={Home} />
                <Route exact path='/about' component={About} />
                <Route exact path='/projects' component={Projects} />
                <Route exact path='/contact' component={Contact} />
              </Switch>
            </animated.div>
          ))}
        </Container>
      </ThemeProvider>
    </PortContext.Provider>
  );
};

const SplitLeft = styled.div`
  width: 50%;
  height: 100vh;
  background: ${props => props.theme.left};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
`;

const SplitRight = styled.div`
  width: 50%;
  height: 100vh;
  background: ${props => props.theme.right};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
`;

export default App;
