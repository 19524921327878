import React from 'react';
import PortContext from '../../Contexts/PortContext';
import styled from 'styled-components';

const DynamicBurger = () => {
  return (
    <PortContext.Consumer>
      {({ handleOpen, handleClose, isMenu, isPalette }) => (
        <BurgerContainer>
          <input
            type='checkbox'
            id='hamburger'
            onChange={isMenu ? handleClose : handleOpen}
            checked={isMenu}
          />
          <label htmlFor='hamburger'>
            <svg id='dburger'>
              <line x1='50%' y1={isMenu ? '20%' : '40%'} x2='50%' y2='80%' />
              <line x1='50%' y1='20%' x2='50%' y2='80%' />
              {/* <circle cx="50%" cy="50%" r="48" class="ham-circle" /> */}
            </svg>
          </label>
        </BurgerContainer>
      )}
    </PortContext.Consumer>
  );
};

const BurgerContainer = styled.div`
  height: 40;
  width: 40;
  z-index: 999;
`;

// const BurgerInput = styled.input``;

export default DynamicBurger;
