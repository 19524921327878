import React from "react";
import Particles from "react-particles-js";

const Snow = () => {
  const particleConfig = {
    particles: {
      number: {
        value: 15,
        density: {
          enable: true,
          value_area: 800
        }
      },
      size: {
        anim: {
          enable: true,
          speed: 2,
          size_min: 0.5
        },
        value: 5,
        random: true
      },
      opacity: {
        anim: {
          enable: true,
          speed: 1,
          opacity_min: 0.3
        },
        value: 0.5,
        random: true
      },
      line_linked: {
        enable: false
      },
      move: {
        speed: 1.5
      }
    }
  };

  return <Particles className="stars" params={particleConfig} />;
};

export default Snow;
