import { css } from "styled-components";
const devices = {
  desktopLarge: 2560,
  desktop: 1999.98,
  laptopLarge: 1440,
  laptop: 991.98,
  tabletLand: 823,
  tablet: 767.98,
  mobileLand: 670,
  mobile: 419.98
};
export default Object.keys(devices).reduce((acc, device) => {
  acc[device] = (...args) => css`
    @media (max-width: ${devices[device]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});
