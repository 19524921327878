import React from "react";
import styled, { css } from "styled-components";

const OutlineText = ({
  size,
  rotate,
  pos,
  top,
  right,
  bottom,
  left,
  transX,
  transY,
  transZ,
  color,
  ...props
}) => {
  return (
    <Outline
      size={size}
      rotate={rotate}
      transX={transX}
      transY={transY}
      transZ={transZ}
      pos={pos}
      top={top}
      right={right}
      bottom={left}
      left={left}
      color={color}
    >
      {props.children}
    </Outline>
  );
};

const Outline = styled.div`
  color: var(--primary);
  font-weight: bold;
  font-size: ${props => props.size}rem;
  z-index: 3;
  transition: 0.2s linear;
  color: transparent;
  -webkit-text-stroke: ${props =>
    props.color ? `2px ${props.color}` : `2px ${props.theme.secondary}`};
  transform: ${props =>
    props.rotate &&
    css`
    rotate(${props.rotate}deg)
    `};
  transform: ${props =>
    props.transX &&
    css`
    translateX(${props.transX})
    `};
  transform: ${props =>
    props.transY &&
    css`
    translateY(${props.transY})
    `};
  transform: ${props =>
    props.transZ &&
    css`
    translateZ(${props.transZ})
    `};

  @supports not (-webkit-text-stroke: 2px red) {
    & {
      text-shadow: 3px 3px 0 var(--primary), -1px -1px 0 var(--primary),
        1px -1px 0 var(--primary), -1px 1px 0 var(--primary),
        1px 1px 0 var(--primary);
    }
  }
`;

export default OutlineText;
