import React from "react";
import { NavLink, Link } from "react-router-dom";
import Icon from "../utils/Icon";
import styled from "styled-components";
import media from "../styles/Breakpoints";
import SideControls from "./SideControls";

const SideLinks = () => {
  const routes = [
    {
      to: "/about",
      title: "about"
    },
    {
      to: "/projects",
      title: "projects"
    },
    {
      to: "/contact",
      title: "contact"
    }
  ].map(({ to, title }) => (
    <SideLinksItem key={to}>
      <NavLink exact to={to} activeClassName='active'>
        {title}
      </NavLink>
    </SideLinksItem>
  ));

  return (
    <SideContainer>
      <SideContent>
        <Brand>
          <Link to='/'>
            <Icon />
          </Link>
        </Brand>
        <SideItems>
          <SideLinksContainer>{routes}</SideLinksContainer>
          <SideControls />
        </SideItems>
      </SideContent>
    </SideContainer>
  );
};

const SideContainer = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 7;
`;

const SideContent = styled.div`
  height: calc(100vh - 6rem);
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  max-width: 50px;
  margin: 0 3rem;
  ${media.tabletLand`
  height: calc(100vh - 2rem);
  margin: 0 1rem;
   `}
  ${media.mobileLand`
  height: calc(100vh - 2rem);
  margin: 0 1rem;
   `}
  ${media.mobile`
  height: calc(100vh - 2rem);
  margin: 0 1rem;
   `}
`;

const Brand = styled.div`
  width: 50px;
  height: 50px;
  text-align: center;
`;

const SideItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  ${media.tabletLand`
    display: none;
  `}
  ${media.mobileLand`
    display: none;
  `}
  ${media.mobile`
    display: none;
  `}
`;

const SideLinksContainer = styled.ul`
  list-style: none;
  transform: rotate(-90deg) translate3d(-30%, 0px, 0px);
  display: flex;
  flex-direction: row-reverse;
  color: #fff;
  ${media.mobile`
      display: none;
   `}
`;

const SideLinksItem = styled.li`
  position: relative;
  line-height: 1;
  padding: 20px;
  & a {
    /* background: linear-gradient(to bottom, var(--gold) 0%, var(--gold) 100%); */
    background: ${props =>
      `linear-gradient(to bottom, ${props.theme.secondary} 0%, ${props.theme.secondary} 100%)`};
    background-position: 0px 55%;
    background-repeat: repeat-x;
    background-size: 14px 0px;
    color: #fff;
    text-decoration: none;
    transition: background-size 0.3s;
    padding: 0 0.5rem;
    &:hover {
      background-size: 4px 50px;
      opacity: 1;
    }
  }
`;

export default SideLinks;
