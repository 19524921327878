import React from "react";
import { Wand, Palette, Github } from "../utils/Icons";
import styled from "styled-components";
import media from "../styles/Breakpoints";
import PortContext from "../../Contexts/PortContext";

const SideControls = () => {
  return (
    <PortContext.Consumer>
      {({ handleParticles, handlePalette, handleOpen }) => (
        <SideButtons>
          <SideButtonsItem>
            <a
              href="https://github.com/paoburgos"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Github />
            </a>
          </SideButtonsItem>
          <SideButtonsItem>
            <Wand onClick={handleParticles} />
          </SideButtonsItem>
          <SideButtonsItem>
            <ThemeContainer>
              <Palette
                onClick={() => {
                  handlePalette();
                  handleOpen();
                }}
              />
            </ThemeContainer>
          </SideButtonsItem>
        </SideButtons>
      )}
    </PortContext.Consumer>
  );
};

const SideButtons = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  transform: translate3d(-50%, 0px, 0px);
  color: #fff;
  font-size: 1.5rem;
  ${media.mobile`
    /* flex-direction: row; */
    width: 100%;
  `}
`;

const SideButtonsItem = styled.li`
  margin: 0.5rem 0;
  cursor: pointer;
  ${media.mobile`
    margin: 0.1rem 0;
    /* display: none; */
  `}
`;

const ThemeContainer = styled.div`
  position: relative;
`;

export default SideControls;
