import React from 'react';
import styled, { keyframes } from 'styled-components';
import './Marquee.css';
import media from '../styles/Breakpoints';

const Marquee = () => {
  // DO NOT DELETE
  // Path length
  // React.useEffect(() => {
  //   const logo = document.querySelectorAll('#marquee path');

  //   for (let i = 0; i < logo.length; i++) {
  //     console.log(`Letter ${i} is ${logo[i].getTotalLength().toFixed(2)}`);
  //   }
  // }, []);

  return (
    <>
      <MarqueeContainer
        width='755'
        height='234'
        viewBox='0 0 755 234'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M70.112 11.938V95H53.452V59.657H17.871V95H1.211V11.938H17.871V46.091H53.452V11.938H70.112Z'
          stroke='white'
          strokeWidth='2'
        />
        <path
          d='M94.9588 21.22C92.0235 21.22 89.5642 20.3077 87.5808 18.483C85.6768 16.579 84.7248 14.2387 84.7248 11.462C84.7248 8.68534 85.6768 6.38468 87.5808 4.56001C89.5642 2.65601 92.0235 1.70401 94.9588 1.70401C97.8942 1.70401 100.314 2.65601 102.218 4.56001C104.201 6.38468 105.193 8.68534 105.193 11.462C105.193 14.2387 104.201 16.579 102.218 18.483C100.314 20.3077 97.8942 21.22 94.9588 21.22ZM103.17 29.074V95H86.5098V29.074H103.17Z'
          stroke='white'
          strokeWidth='2'
        />
        <path
          d='M137.837 77.507L123.914 110.946H113.204L120.939 77.507H137.837Z'
          stroke='white'
          strokeWidth='2'
        />
        <path
          d='M239.485 60.609C239.485 62.989 239.327 65.131 239.009 67.035H190.814C191.211 71.795 192.877 75.5237 195.812 78.221C198.748 80.9183 202.357 82.267 206.641 82.267C212.829 82.267 217.232 79.6093 219.85 74.294H237.819C235.915 80.6407 232.266 85.8767 226.871 90.002C221.477 94.048 214.852 96.071 206.998 96.071C200.652 96.071 194.94 94.6827 189.862 91.906C184.864 89.05 180.937 85.0437 178.081 79.887C175.305 74.7304 173.916 68.7804 173.916 62.037C173.916 55.2144 175.305 49.2247 178.081 44.068C180.858 38.9113 184.745 34.9447 189.743 32.168C194.741 29.3913 200.493 28.003 206.998 28.003C213.266 28.003 218.859 29.3517 223.777 32.049C228.775 34.7463 232.623 38.594 235.32 43.592C238.097 48.5107 239.485 54.183 239.485 60.609ZM222.23 55.849C222.151 51.565 220.604 48.1537 217.589 45.615C214.575 42.997 210.886 41.688 206.522 41.688C202.397 41.688 198.906 42.9573 196.05 45.496C193.274 47.9553 191.568 51.4063 190.933 55.849H222.23Z'
          stroke='white'
          strokeWidth='2'
        />
        <path
          d='M247.362 61.799C247.362 55.135 248.671 49.2247 251.289 44.068C253.986 38.9113 257.596 34.9447 262.118 32.168C266.719 29.3913 271.836 28.003 277.469 28.003C282.387 28.003 286.671 28.9947 290.321 30.978C294.049 32.9613 297.024 35.4603 299.246 38.475V29.074H316.025V95H299.246V85.361C297.104 88.455 294.129 91.0333 290.321 93.096C286.592 95.0793 282.268 96.071 277.35 96.071C271.796 96.071 266.719 94.643 262.118 91.787C257.596 88.931 253.986 84.9247 251.289 79.768C248.671 74.532 247.362 68.5423 247.362 61.799ZM299.246 62.037C299.246 57.991 298.452 54.54 296.866 51.684C295.279 48.7487 293.137 46.5273 290.44 45.02C287.742 43.4333 284.847 42.64 281.753 42.64C278.659 42.64 275.803 43.3937 273.185 44.901C270.567 46.4083 268.425 48.6297 266.759 51.565C265.172 54.421 264.379 57.8323 264.379 61.799C264.379 65.7657 265.172 69.2563 266.759 72.271C268.425 75.2063 270.567 77.4673 273.185 79.054C275.882 80.6407 278.738 81.434 281.753 81.434C284.847 81.434 287.742 80.6803 290.44 79.173C293.137 77.5863 295.279 75.365 296.866 72.509C298.452 69.5737 299.246 66.083 299.246 62.037Z'
          stroke='white'
          strokeWidth='2'
        />
        <path
          d='M348.956 39.308C351.098 35.8173 353.875 33.0803 357.286 31.097C360.777 29.1137 364.743 28.122 369.186 28.122V45.615H364.783C359.547 45.615 355.58 46.8447 352.883 49.304C350.265 51.7633 348.956 56.0473 348.956 62.156V95H332.296V29.074H348.956V39.308Z'
          stroke='white'
          strokeWidth='2'
        />
        <path
          d='M399.804 42.759V74.651C399.804 76.8723 400.32 78.4987 401.351 79.53C402.462 80.482 404.287 80.958 406.825 80.958H414.56V95H404.088C390.046 95 383.025 88.1773 383.025 74.532V42.759H375.171V29.074H383.025V12.771H399.804V29.074H414.56V42.759H399.804Z'
          stroke='white'
          strokeWidth='2'
        />
        <path
          d='M463.671 28.122C468.669 28.122 473.112 29.2327 476.999 31.454C480.886 33.596 483.901 36.809 486.043 41.093C488.264 45.2977 489.375 50.375 489.375 56.325V95H472.715V58.586C472.715 53.35 471.406 49.3437 468.788 46.567C466.17 43.711 462.6 42.283 458.078 42.283C453.477 42.283 449.827 43.711 447.13 46.567C444.512 49.3437 443.203 53.35 443.203 58.586V95H426.543V6.94001H443.203V37.285C445.345 34.429 448.201 32.2077 451.771 30.621C455.341 28.955 459.308 28.122 463.671 28.122Z'
          stroke='white'
          strokeWidth='2'
        />
        <path
          d='M521.878 6.94001V95H505.218V6.94001H521.878Z'
          stroke='white'
          strokeWidth='2'
        />
        <path
          d='M546.787 21.22C543.852 21.22 541.392 20.3077 539.409 18.483C537.505 16.579 536.553 14.2387 536.553 11.462C536.553 8.68534 537.505 6.38468 539.409 4.56001C541.392 2.65601 543.852 1.70401 546.787 1.70401C549.722 1.70401 552.142 2.65601 554.046 4.56001C556.029 6.38468 557.021 8.68534 557.021 11.462C557.021 14.2387 556.029 16.579 554.046 18.483C552.142 20.3077 549.722 21.22 546.787 21.22ZM554.998 29.074V95H538.338V29.074H554.998Z'
          stroke='white'
          strokeWidth='2'
        />
        <path
          d='M607.991 28.122C615.845 28.122 622.192 30.621 627.031 35.619C631.87 40.5377 634.29 47.4397 634.29 56.325V95H617.63V58.586C617.63 53.35 616.321 49.3437 613.703 46.567C611.085 43.711 607.515 42.283 602.993 42.283C598.392 42.283 594.742 43.711 592.045 46.567C589.427 49.3437 588.118 53.35 588.118 58.586V95H571.458V29.074H588.118V37.285C590.339 34.429 593.156 32.2077 596.567 30.621C600.058 28.955 603.866 28.122 607.991 28.122Z'
          stroke='white'
          strokeWidth='2'
        />
        <path
          d='M675.956 28.003C680.875 28.003 685.198 28.9947 688.927 30.978C692.656 32.882 695.591 35.381 697.733 38.475V29.074H714.512V95.476C714.512 101.585 713.282 107.019 710.823 111.779C708.364 116.618 704.675 120.426 699.756 123.203C694.837 126.059 688.887 127.487 681.906 127.487C672.545 127.487 664.849 125.305 658.82 120.942C652.87 116.579 649.498 110.629 648.705 103.092H665.246C666.119 106.107 667.983 108.487 670.839 110.232C673.774 112.057 677.305 112.969 681.43 112.969C686.269 112.969 690.196 111.501 693.211 108.566C696.226 105.71 697.733 101.347 697.733 95.476V85.242C695.591 88.336 692.616 90.9143 688.808 92.977C685.079 95.0397 680.795 96.071 675.956 96.071C670.403 96.071 665.325 94.643 660.724 91.787C656.123 88.931 652.473 84.9247 649.776 79.768C647.158 74.532 645.849 68.5423 645.849 61.799C645.849 55.135 647.158 49.2247 649.776 44.068C652.473 38.9113 656.083 34.9447 660.605 32.168C665.206 29.3913 670.323 28.003 675.956 28.003ZM697.733 62.037C697.733 57.991 696.94 54.54 695.353 51.684C693.766 48.7487 691.624 46.5273 688.927 45.02C686.23 43.4333 683.334 42.64 680.24 42.64C677.146 42.64 674.29 43.3937 671.672 44.901C669.054 46.4083 666.912 48.6297 665.246 51.565C663.659 54.421 662.866 57.8323 662.866 61.799C662.866 65.7657 663.659 69.2563 665.246 72.271C666.912 75.2063 669.054 77.4673 671.672 79.054C674.369 80.6407 677.225 81.434 680.24 81.434C683.334 81.434 686.23 80.6803 688.927 79.173C691.624 77.5863 693.766 75.365 695.353 72.509C696.94 69.5737 697.733 66.083 697.733 62.037Z'
          stroke='white'
          strokeWidth='2'
        />
        <path
          d='M752.322 10.51L750.418 67.987H736.376L734.353 10.51H752.322ZM743.754 95.833C740.74 95.833 738.241 94.9207 736.257 93.096C734.353 91.192 733.401 88.8517 733.401 86.075C733.401 83.2983 734.353 80.9977 736.257 79.173C738.241 77.269 740.74 76.317 743.754 76.317C746.69 76.317 749.109 77.269 751.013 79.173C752.917 80.9977 753.869 83.2983 753.869 86.075C753.869 88.8517 752.917 91.192 751.013 93.096C749.109 94.9207 746.69 95.833 743.754 95.833Z'
          stroke='white'
          strokeWidth='2'
        />
        <path
          d='M17.871 130.938V214H1.211V130.938H17.871Z'
          stroke='white'
          strokeWidth='2'
        />
        <path
          d='M37.6631 130.938H55.1561L42.3041 160.926H30.8801L37.6631 130.938Z'
          stroke='white'
          strokeWidth='2'
        />
        <path
          d='M148.975 147.122C157.067 147.122 163.572 149.621 168.491 154.619C173.489 159.538 175.988 166.44 175.988 175.325V214H159.328V177.586C159.328 172.429 158.019 168.502 155.401 165.805C152.783 163.028 149.213 161.64 144.691 161.64C140.169 161.64 136.559 163.028 133.862 165.805C131.244 168.502 129.935 172.429 129.935 177.586V214H113.275V177.586C113.275 172.429 111.966 168.502 109.348 165.805C106.73 163.028 103.16 161.64 98.6376 161.64C94.0363 161.64 90.3869 163.028 87.6896 165.805C85.0716 168.502 83.7626 172.429 83.7626 177.586V214H67.1026V148.074H83.7626V156.047C85.9046 153.27 88.6416 151.089 91.9736 149.502C95.3849 147.915 99.1136 147.122 103.16 147.122C108.316 147.122 112.918 148.233 116.964 150.454C121.01 152.596 124.143 155.69 126.365 159.736C128.507 155.928 131.601 152.874 135.647 150.573C139.772 148.272 144.215 147.122 148.975 147.122Z'
          stroke='white'
          strokeWidth='2'
        />
        <path
          d='M280.723 156.642C280.723 161.085 279.652 165.25 277.51 169.137C275.448 173.024 272.155 176.158 267.633 178.538C263.191 180.918 257.558 182.108 250.735 182.108H236.812V214H220.152V130.938H250.735C257.161 130.938 262.635 132.049 267.157 134.27C271.679 136.491 275.051 139.546 277.272 143.433C279.573 147.32 280.723 151.723 280.723 156.642ZM250.021 168.661C254.623 168.661 258.034 167.63 260.255 165.567C262.477 163.425 263.587 160.45 263.587 156.642C263.587 148.55 259.065 144.504 250.021 144.504H236.812V168.661H250.021Z'
          stroke='white'
          strokeWidth='2'
        />
        <path
          d='M288.268 180.799C288.268 174.135 289.577 168.225 292.195 163.068C294.892 157.911 298.502 153.945 303.024 151.168C307.625 148.391 312.742 147.003 318.375 147.003C323.293 147.003 327.578 147.995 331.227 149.978C334.956 151.961 337.93 154.46 340.152 157.475V148.074H356.931V214H340.152V204.361C338.01 207.455 335.035 210.033 331.227 212.096C327.498 214.079 323.174 215.071 318.256 215.071C312.702 215.071 307.625 213.643 303.024 210.787C298.502 207.931 294.892 203.925 292.195 198.768C289.577 193.532 288.268 187.542 288.268 180.799ZM340.152 181.037C340.152 176.991 339.358 173.54 337.772 170.684C336.185 167.749 334.043 165.527 331.346 164.02C328.648 162.433 325.753 161.64 322.659 161.64C319.565 161.64 316.709 162.394 314.091 163.901C311.473 165.408 309.331 167.63 307.665 170.565C306.078 173.421 305.285 176.832 305.285 180.799C305.285 184.766 306.078 188.256 307.665 191.271C309.331 194.206 311.473 196.467 314.091 198.054C316.788 199.641 319.644 200.434 322.659 200.434C325.753 200.434 328.648 199.68 331.346 198.173C334.043 196.586 336.185 194.365 337.772 191.509C339.358 188.574 340.152 185.083 340.152 181.037Z'
          stroke='white'
          strokeWidth='2'
        />
        <path
          d='M402.476 215.071C396.13 215.071 390.418 213.683 385.34 210.906C380.263 208.05 376.257 204.044 373.321 198.887C370.465 193.73 369.037 187.78 369.037 181.037C369.037 174.294 370.505 168.344 373.44 163.187C376.455 158.03 380.541 154.064 385.697 151.287C390.854 148.431 396.606 147.003 402.952 147.003C409.299 147.003 415.051 148.431 420.207 151.287C425.364 154.064 429.41 158.03 432.345 163.187C435.36 168.344 436.867 174.294 436.867 181.037C436.867 187.78 435.32 193.73 432.226 198.887C429.212 204.044 425.086 208.05 419.85 210.906C414.694 213.683 408.902 215.071 402.476 215.071ZM402.476 200.553C405.491 200.553 408.307 199.839 410.925 198.411C413.623 196.904 415.765 194.682 417.351 191.747C418.938 188.812 419.731 185.242 419.731 181.037C419.731 174.77 418.065 169.97 414.733 166.638C411.481 163.227 407.474 161.521 402.714 161.521C397.954 161.521 393.948 163.227 390.695 166.638C387.522 169.97 385.935 174.77 385.935 181.037C385.935 187.304 387.482 192.144 390.576 195.555C393.75 198.887 397.716 200.553 402.476 200.553Z'
          stroke='white'
          strokeWidth='2'
        />
        <path
          d='M465.748 125.94V214H449.088V125.94H465.748Z'
          stroke='white'
          strokeWidth='2'
        />
        <path
          d='M511.482 215.071C505.135 215.071 499.423 213.683 494.346 210.906C489.269 208.05 485.262 204.044 482.327 198.887C479.471 193.73 478.043 187.78 478.043 181.037C478.043 174.294 479.511 168.344 482.446 163.187C485.461 158.03 489.546 154.064 494.703 151.287C499.86 148.431 505.611 147.003 511.958 147.003C518.305 147.003 524.056 148.431 529.213 151.287C534.37 154.064 538.416 158.03 541.351 163.187C544.366 168.344 545.873 174.294 545.873 181.037C545.873 187.78 544.326 193.73 541.232 198.887C538.217 204.044 534.092 208.05 528.856 210.906C523.699 213.683 517.908 215.071 511.482 215.071ZM511.482 200.553C514.497 200.553 517.313 199.839 519.931 198.411C522.628 196.904 524.77 194.682 526.357 191.747C527.944 188.812 528.737 185.242 528.737 181.037C528.737 174.77 527.071 169.97 523.739 166.638C520.486 163.227 516.48 161.521 511.72 161.521C506.96 161.521 502.954 163.227 499.701 166.638C496.528 169.97 494.941 174.77 494.941 181.037C494.941 187.304 496.488 192.144 499.582 195.555C502.755 198.887 506.722 200.553 511.482 200.553Z'
          stroke='white'
          strokeWidth='2'
        />
        <path
          d='M565.472 214.833C562.457 214.833 559.958 213.921 557.975 212.096C556.071 210.192 555.119 207.852 555.119 205.075C555.119 202.298 556.071 199.998 557.975 198.173C559.958 196.269 562.457 195.317 565.472 195.317C568.407 195.317 570.827 196.269 572.731 198.173C574.635 199.998 575.587 202.298 575.587 205.075C575.587 207.852 574.635 210.192 572.731 212.096C570.827 213.921 568.407 214.833 565.472 214.833Z'
          stroke='white'
          strokeWidth='2'
        />
        <path d='M220 223H576V233H220V223Z' stroke='white' strokeWidth='2' />
      </MarqueeContainer>
    </>
  );
};

const MarqueAnimate = keyframes`
  to {
    stroke-dashoffset: 1;
  }
`;

const MarqueeFill = keyframes`
  from {
    fill: transparent;
  }
  to {
    fill: #fff;
  }
`;

const MarqueeContainer = styled.svg`
  width: 700px;
  animation: ${MarqueeFill} 0.5s ease forwards 2.5s;

  & path:nth-child(1) {
    stroke-dasharray: 443px;
    stroke-dashoffset: 443px;
    animation: ${MarqueAnimate} 2s ease forwards 0.5s;
  }

  & path:nth-child(2) {
    stroke-dasharray: 228.42px;
    stroke-dashoffset: 228.42px;
    animation: ${MarqueAnimate} 2s ease forwards 0.8s;
  }

  & path:nth-child(3) {
    stroke-dasharray: 98.15px;
    stroke-dashoffset: 98.15px;
    animation: ${MarqueAnimate} 2s ease forwards 0.1s;
  }

  & path:nth-child(4) {
    stroke-dasharray: 390.74px;
    stroke-dashoffset: 390.74px;
    animation: ${MarqueAnimate} 2s ease forwards 0.13s;
  }

  & path:nth-child(5) {
    stroke-dasharray: 387.47px;
    stroke-dashoffset: 387.47px;
    animation: ${MarqueAnimate} 2s ease forwards 0.16s;
  }

  & path:nth-child(6) {
    stroke-dasharray: 215px;
    stroke-dashoffset: 215px;
    animation: ${MarqueAnimate} 2s ease forwards 0.19s;
  }

  & path:nth-child(7) {
    stroke-dasharray: 262.62px;
    stroke-dashoffset: 262.62px;
    animation: ${MarqueAnimate} 2s ease forwards 0.21s;
  }

  & path:nth-child(8) {
    stroke-dasharray: 396.11px;
    stroke-dashoffset: 396.11px;
    animation: ${MarqueAnimate} 2s ease forwards 0.24s;
  }

  & path:nth-child(9) {
    stroke-dasharray: 209.44px;
    stroke-dashoffset: 209.44px;
    animation: ${MarqueAnimate} 2s ease forwards 0.27s;
  }

  & path:nth-child(10) {
    stroke-dasharray: 228.42px;
    stroke-dashoffset: 228.42px;
    animation: ${MarqueAnimate} 2s ease forwards 0.3s;
  }

  & path:nth-child(11) {
    stroke-dasharray: 351.77px;
    stroke-dashoffset: 351.77px;
    animation: ${MarqueAnimate} 2s ease forwards 0.33s;
  }

  & path:nth-child(12) {
    stroke-dasharray: 533.05px;
    stroke-dashoffset: 533.05px;
    animation: ${MarqueAnimate} 2s ease forwards 0.36s;
  }

  & path:nth-child(13) {
    stroke-dasharray: 210.38px;
    stroke-dashoffset: 210.38px;
    animation: ${MarqueAnimate} 2s ease forwards 0.39s;
  }

  & path:nth-child(14) {
    stroke-dasharray: 199.44px;
    stroke-dashoffset: 199.44px;
    animation: ${MarqueAnimate} 2s ease forwards 0.42s;
  }

  & path:nth-child(15) {
    stroke-dasharray: 92.29px;
    stroke-dashoffset: 92.29px;
    animation: ${MarqueAnimate} 2s ease forwards 0.45s;
  }

  & path:nth-child(16) {
    stroke-dasharray: 544.63px;
    stroke-dashoffset: 544.63px;
    animation: ${MarqueAnimate} 2s ease forwards 0.48s;
  }

  & path:nth-child(17) {
    stroke-dasharray: 357.23px;
    stroke-dashoffset: 357.23px;
    animation: ${MarqueAnimate} 2s ease forwards 0.51s;
  }

  & path:nth-child(18) {
    stroke-dasharray: 387.47px;
    stroke-dashoffset: 387.47px;
    animation: ${MarqueAnimate} 2s ease forwards 0.54s;
  }

  & path:nth-child(19) {
    stroke-dasharray: 331.79px;
    stroke-dashoffset: 331.79px;
    animation: ${MarqueAnimate} 2s ease forwards 0.57s;
  }

  & path:nth-child(20) {
    stroke-dasharray: 209.44px;
    stroke-dashoffset: 209.44px;
    animation: ${MarqueAnimate} 2s ease forwards 0.6s;
  }

  & path:nth-child(21) {
    stroke-dasharray: 331.8px;
    stroke-dashoffset: 331.8px;
    animation: ${MarqueAnimate} 2s ease forwards 0.63s;
  }

  & path:nth-child(22) {
    stroke-dasharray: 63.35px;
    stroke-dashoffset: 63.35px;
    animation: ${MarqueAnimate} 2s ease forwards 0.66s;
  }

  & path:nth-child(23) {
    stroke-dasharray: 1232px;
    stroke-dashoffset: 1232px;
    animation: ${MarqueAnimate} 2s ease forwards 0.69s;
  }

  ${media.tabletLand`
      width: 488px;
      height: 150px;
  `}
  ${media.tablet`
      width: 488px;
      height: 150px;
  `}
  ${media.mobileLand`
      width: 330px;
      height: 100px;
  `}
  ${media.mobile`
      width: 100%;
      height: 100px;
  `}
`;

export default Marquee;
