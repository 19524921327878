import r1 from './r1.png';
import r2 from './r2.png';
import r3 from './r3.png';

// export default [r1, r2, r3];
export default [
  { original: r1, thumbnail: r1 },
  { original: r2, thumbnail: r2 },
  { original: r3, thumbnail: r3 }
];
