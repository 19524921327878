import React from "react";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";
import Outline from "../utils/OutlineText";
import media from "../styles/Breakpoints";
import { Helmet } from "react-helmet";

const About = () => {
  const slide = useSpring({
    // from: {
    //   marginLeft: 200
    // },
    // marginLeft: 0
    from: {
      transform: "translate(-100%, 0)"
    },
    transform: "translate(0, 0)"
  });

  // const launch = useSpring({
  //   from: {
  //     marginTop: 200
  //   },
  //   marginTop: 0,
  //   delay: 400
  // });

  const _display = useSpring({
    from: { opacity: 0, x: 20, height: "0%" },
    height: "100%",
    opacity: 1,
    x: 0,
    delay: 800
  });

  return (
    <AboutContainer style={slide}>
      <Helmet>
        <title>About</title>
        <meta name="description" content="About page for Paolo Burgos" />
      </Helmet>
      <AboutInfo
        style={{
          ..._display,
          transform: _display.x.interpolate(x => `translate3d(0,${x}px,0)`)
        }}
      >
        <AboutHeader>
          <Outline size={2}>I'm Paolo Burgos.</Outline>
          I'm a full stack dev with some design sense.
        </AboutHeader>

        <AboutLine />

        <AboutText>
          I am a Full-Stack developer for Refresh Telecom Smart City in Orlando,
          FL. I have a deep rooted passion for creating clean, intuititve, and
          dynamic user experiences.
          <br />
          <br />I enjoy utiilizing my skills using modern technologies and
          building something that will matter for a long time. That being said,
          I am always eager to explore new and different technologies.
        </AboutText>
      </AboutInfo>
    </AboutContainer>
  );
};

const AboutContainer = styled(animated.div)`
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  background: ${props => props.theme.left};
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  ${media.mobile`
  padding: 2rem;
  `}
`;

const AboutInfo = styled(animated.div)`
  padding: 1rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 900px;
  margin: auto;
  ${media.laptopLarge`
    max-width: 600px;
  `}
  ${media.laptop`
  max-width: 600px;
  margin-top: 3rem;
  `}
  ${media.tabletLand`
    max-width: 600px;
    margin-top: 3rem;
  `}
  ${media.tablet`
    max-width: 450px;
    margin-top: 3rem;
  `}
  ${media.mobile`
    max-width: 450px;
    margin-top: 0;
  `}
`;

const AboutHeader = styled.div`
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
  display: inline-block;
  ${media.mobile`
  font-size: 1.3rem;
  `}
`;

const AboutLine = styled.div`
  border-bottom: 3px solid #fff;
  width: 40px;
  margin: 2rem 0;
  ${media.mobile`
    margin: 1rem 0;
  `}
`;

const AboutText = styled.div`
  color: ${props => props.theme.text};
  font-size: 1.3rem;

  ${media.mobileLand`
  font-size: 1rem;
  `}
  ${media.mobile`
  font-size: 1rem;
  `}
`;

export default About;
