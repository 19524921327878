import uuid from "uuid";
import notes from "../../assets/images/projects/notes.PNG";
import message from "../../assets/images/projects/imsg.PNG";
import money from "../../assets/images/projects/currency.PNG";
import novo from "../../assets/images/projects/novo.PNG";
import cat from "../../assets/images/projects/cat.PNG";
import cocreate from "../../assets/images/projects/cocreate2.PNG";
import telecom from "../../assets/images/projects/refresh.PNG";
import CocreateImg from "../../assets/images/cocreate/CocreateImg";
import NovoImg from "../../assets/images/novo/NovoImg";
import RefreshImg from "../../assets/images/refresh/RefreshImg";

const projects = [
  {
    id: uuid.v4(),
    name: "Cocreate",
    desc:
      "A MERN stack app for engaging with fellow developers. Users have the ability to create their profile and integrate their github account. This is a demo project that utilizes modern technologies such as React, Redux, Express, and MongoDB.",
    img: cocreate,
    link: "https://cocreate.herokuapp.com",
    github: "",
    style: {
      backgroundImage: "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
      color: "#333"
    },
    theme: { primary: "#41889a", secondary: "#7f9aa7", link: "#FD6C6C" },
    tag: "MERN stack",
    carouselImg: CocreateImg,
    tech: ["React", "Redux", "NodeJS", "Express", "MongoDB"]
  },

  {
    id: uuid.v4(),
    name: "Refresh Telecom",
    desc:
      "A telecom site for a company to showcase various services in the Telecom industry as well as Networking and IT field.",
    img: telecom,
    link: "",
    github: "",
    style: {
      background: "#2A2C2E",
      color: "#7f9aa7"
    },
    theme: { primary: "#03AFA8", secondary: "#7f9aa7" },
    tag: "PHP, UI Design",
    carouselImg: RefreshImg,
    tech: ["PHP", "UI/UX Design"]
  },
  {
    id: uuid.v4(),
    name: "iMessage Clone",
    desc: "A fun and simple iMessage clone that utilizes Socket.io.",
    img: message,
    link: "",
    github: "",
    style: { background: "#46B6BB", color: "#fff" },
    theme: { primary: "#EFF0F2", secondary: "#C8D0D8" },
    tag: "NodeJS, Socket.IO, React",
    carouselImg: [],
    tech: ["NodeJS", "Socket.IO", "React"]
  },
  {
    id: uuid.v4(),
    name: "Purradise City",
    desc: "A gallery that shows cat's statistics and behaviors.",
    img: cat,
    link: "https://cat-api.paoloburgos.now.sh/",
    github: "",
    style: {
      background: "#2b2f34",
      color: "#fff"
    },
    theme: { primary: "#1fb9f4", secondary: "#7f9aa7", link: "#FFF" },
    tag: "React, Redux",
    carouselImg: [],
    tech: ["React", "Redux", "Apex Charts"]
  },
  {
    id: uuid.v4(),
    name: "Memo",
    desc:
      "A task manager app that is uses Redux-persist for data storage. User can create notes and customize memo cards.",
    img: notes,
    link: "https://notes-app.paoloburgos.now.sh/",
    github: "",
    style: { background: "#E65860", color: "#1d1866" },
    theme: { primary: "#1d1866", secondary: "#3a345a", link: "#FFF" },
    tag: "React, Redux, Redux-Persist",
    carouselImg: [],
    tech: ["React", "Redux", "Redux-persist"]
  },
  {
    id: uuid.v4(),
    name: "Currency Converter",
    desc: "Simple currency converter app.",
    img: money,
    link: "https://foreign-exchange-rates.paoloburgos.now.sh/",
    github: "",
    style: { background: "#131313", color: "#fff" },
    theme: { primary: "#1FB9F4", secondary: "#7f9aa7", link: "#FFF" },
    tag: "React, ContextAPI",
    carouselImg: [],
    tech: ["React", "ContextAPI"]
  }
];
// {
//   id: uuid.v4(),
//   name: "NoVo",
//   desc:
//     "An in-house application that manages client's information, service plans, and billing info. Apart from thousands of data tables, the application features a ticketing system and a buffer application for calculation.",
//   img: novo,
//   link: "",
//   github: "",
//   style: { background: "#131313", color: "#fff" },
//   theme: { primary: "#fff", secondary: "#7f9aa7" },
//   tag: "PHP, Javascript, MySQL, UI/UX Design",
//   carouselImg: NovoImg,
//   tech: ["Javascript", "PHP", "MySQL", "UI/UX Design"]
// },
export default projects;
