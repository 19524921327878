import n1 from './n1.png';
import n2 from './n2.png';
import n3 from './n3.png';
import n4 from './n4.png';
import n5 from './n5.png';

// export default [n1, n2, n3, n4, n5];
export default [
  { original: n1, thumbnail: n1 },
  { original: n2, thumbnail: n2 },
  { original: n3, thumbnail: n3 },
  { original: n4, thumbnail: n4 },
  { original: n5, thumbnail: n5 }
];
