import React from "react";
import ReactDOM from "react-dom";
import { useTransition, useSpring, animated } from "react-spring";
import { Github, ArrowRight, Close } from "./Icons";
import styled from "styled-components";
import Outline from "../utils/OutlineText";
import media from "../styles/Breakpoints";
import uuid from "uuid";
import ImageGallery from "react-image-gallery";

const Overlay = ({ currCard, closeOverlay }) => {
  const expand = useTransition(currCard, null, {
    from: {
      opacity: 0,
      //   x: 0,
      transform: "translate(100%, 0)"
    },
    enter: {
      opacity: 1,
      //   x: 100,
      transform: "translate(0, 0)"
    },
    leave: {
      opacity: 0,
      //   x: 0,
      transform: "translate(100%, 0)"
    }
  });

  const up = useSpring({
    from: {
      transform: "translate(0, 100%)"
    },
    transform: "translate(0, 0)",
    delay: 100
  });

  return ReactDOM.createPortal(
    expand.map(({ item, props, key }) => {
      // console.log(item);
      return (
        item && (
          <React.Fragment key={key}>
            <OverlayContainer
              key={key}
              // style={{
              //   ...props
              //   //   height: props.x.interpolate(x => `${x}vh`),
              //   //   width: props.x.interpolate(x => `${x}%`)
              // }}
              style={{ ...props, ...item.style }}
            >
              <OverlayCloseButton
                type="close"
                onClick={() => {
                  closeOverlay();
                }}
              />
              <OverlayContent style={up}>
                <OverlayItem1 color={item.theme.secondary}>
                  <OverlayTitle color={item.theme.primary}>
                    <Outline color={item.theme.primary}>{item.name}</Outline>
                  </OverlayTitle>
                  <OverlayDesc>{item.desc}</OverlayDesc>
                  <OverlayLink color={item.theme.link}>
                    {item.link ? (
                      <DemoLink
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Demo App <ArrowRight />
                      </DemoLink>
                    ) : null}
                  </OverlayLink>
                </OverlayItem1>
                <OverlayItem2 color={item.theme.secondary}>
                  <ul>
                    {item.tech.map(data => (
                      <li key={uuid.v4()}>{data}</li>
                    ))}
                  </ul>
                </OverlayItem2>
                <OverlayItem3>
                  <ImageGallery
                    items={item.carouselImg}
                    showFullscreenButton={false}
                    showPlayButton={false}
                  />
                </OverlayItem3>
                <OverlayItem4 color={item.theme.primary}>
                  {item.github ? (
                    <a href={item.github}>
                      <Github />
                    </a>
                  ) : null}
                  Made with <span>❤</span> by Paolo Burgos
                </OverlayItem4>
              </OverlayContent>
            </OverlayContainer>
          </React.Fragment>
        )
      );
    }),
    document.getElementById("overlay-root")
  );
};

const OverlayContainer = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 99;
  overflow-y: auto;
`;
const OverlayContent = styled(animated.div)`
  display: grid;
  grid-template-areas:
    'content tech'
    'carousel carousel'
    'flink flink';

  grid-gap: 1rem;
  width: 100%;
  max-width: 1200px;
  margin: 3rem auto;
  align-content: center;
  padding: 0;

  ${media.laptopLarge`
    padding: 0 3rem;
  `}
  ${media.laptop`
    padding: 0;
  `}
  ${media.mobile`
    grid-template-areas:
      'content' 
      'tech'
      'carousel'
      'flink';
    margin: 0 auto;
  `}
`;

const OverlayCloseButton = styled(Close)`
  position: sticky;
  position: -webkit-sticky;
  top: 1.5rem;
  right: 0;
  margin: 1.5rem;
  padding: 0.2rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  box-shadow: 0 3px 15px 2px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 15px 2px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 3px 15px 2px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  z-index: 100;
  cursor: pointer;
`;

const OverlayTitle = styled.div`
  color: ${props => props.color && props.color};
  font-size: 2rem;
  margin: 0 0 2rem 0;
`;

const OverlayDesc = styled.div`
  font-size: 1.2rem;
  line-height: 2;
  width: 80%;
  ${media.mobile`
    width: 100%;
  `}
`;

const OverlayLink = styled.div`
  margin: 3rem 0;
  & a {
    color: ${props => props.color && props.color};
    position: relative;
    text-decoration: none;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: ${props => props.color && props.color};
      visibility: hidden;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;
    }
    &:hover::before {
      visibility: visible;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }
`;

const DemoLink = styled.a`
  & svg {
    font-size: 1.5rem;
    transform: translateY(7px);
  }
`;

const OverlayItem1 = styled.div`
  grid-area: content;
  color: ${props => props.color && props.color};
  margin: 3rem 0;
  max-width: 1200px;
  & ${OverlayTitle} {
    font-size: 3.5rem;
  }
  ${media.mobile`
    margin: 0 1.5rem;
  `}
`;
const OverlayItem2 = styled.div`
  grid-area: tech;
  margin: 3rem 0;
  color: ${props => props.color && props.color};

  & ul {
    list-style: none;
    padding: 0;
  }

  & ul li {
    border-top: ${props => props.color && `1px solid ${props.color}`};
    border-bottom: ${props => props.color && `1px solid ${props.color}`};
    padding: 1.5rem 0;
    margin-top: -1px;
  }
  ${media.mobile`
    margin: 0 1.5rem;
  `}
`;
const OverlayItem3 = styled.div`
  grid-area: carousel;
  margin: 3rem 0;
  ${media.mobile`
    max-height: 500px;
  `}
`;

const OverlayItem4 = styled.div`
  grid-area: flink;
  margin: 3rem auto;
  & a {
    color: ${props => props.color && props.color};
  }
  & span {
    color: red;
  }
`;

export default Overlay;
