export default {
  dark: {
    id: "dark",
    name: "Dark Knight",
    primary: "#333",
    secondary: "#ecc882",
    text: "#797979",
    darkText: "#252728",
    left: "#000",
    right: "#1d1f23",
    box: "#131313"
  },
  halloween: {
    id: "halloween",
    name: "Halloween",
    primary: "#ec7c2d",
    secondary: "#ff901e",
    text: "#797979",
    darkText: "#252728",
    left: "#000",
    right: "#ec7c2d",
    box: "#131313"
  },
  carrot: {
    id: "carrot",
    name: "Carrot",
    primary: "#bf5f31",
    secondary: "#216d46",
    text: "#c6c6c6",
    darkText: "#252728",
    left: "#60bb78",
    right: "#bf5f31",
    box: "#131313"
  },
  aqua: {
    id: "aqua",
    name: "Aqua",
    primary: "#bf5f31",
    secondary: "#82e7ec",
    text: "#c6c6c6",
    darkText: "#252728",
    left: "#0b3b40",
    right: "#008794",
    box: "#131313"
  },
  broncos: {
    id: "broncos",
    name: "Broncos",
    primary: "#bf5f31",
    secondary: "#ff6b26",
    text: "#c6c6c6",
    darkText: "#252728",
    left: "#050f21",
    right: "#bf5c2d",
    box: "#131313"
  },
  clark: {
    id: "clark",
    name: "Clark",
    primary: "#bf5f31",
    secondary: "#e4cd5c",
    text: "#c6c6c6",
    darkText: "#252728",
    left: "#b34949",
    right: "#3f6773",
    box: "#131313"
  }
};
