import React from "react";
import { NavLink } from "react-router-dom";
import PortContext from "../../Contexts/PortContext";
import styled, { css, keyframes } from "styled-components";
import media from "../styles/Breakpoints";
import { Palette, Github, Wand } from "../utils/Icons";

const NavLinks = ({ handleClose }) => {
  const routes = [
    {
      to: "/",
      title: "home"
    },
    {
      to: "/about",
      title: "about"
    },
    {
      to: "/projects",
      title: "projects"
    },
    {
      to: "/contact",
      title: "contact"
    }
  ].map(({ to, title }) => (
    <LinkItem key={to} onClick={() => handleClose()}>
      <NavLink exact to={to} activeClassName="active">
        {title}
      </NavLink>
    </LinkItem>
  ));

  return (
    <PortContext.Consumer>
      {({ isMenu, handleParticles, handleClose, handlePalette }) => (
        <MenuOverlay isMenu={isMenu}>
          <MenuItem1>
            <MenuLinks isMenu={isMenu}>{routes}</MenuLinks>
          </MenuItem1>
          <MenuWrapper>
            <MenuItem2>
              <a
                href="https://github.com/paoburgos"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Github onClick={handleClose} />
              </a>
              <Wand
                onClick={() => {
                  handleParticles();
                  handleClose();
                }}
              />
              <Palette onClick={handlePalette} />
            </MenuItem2>
            <MenuItem3>
              Made with <span>❤</span> by Paolo Burgos
            </MenuItem3>
          </MenuWrapper>
        </MenuOverlay>
      )}
    </PortContext.Consumer>
  );
};

const MenuOverlay = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background: #000; */
  /* background: #131517; */
  background: ${props => props.theme.right};
  /* display: grid;
  grid-template-columns: 1fr; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /* align-items: flex-end; */
  z-index: 10;
  height: ${props => (props.isMenu ? "100vh" : "0vh")};
  opacity: ${props => (props.isMenu ? 1 : 0)};
  visibility: ${props => (props.isMenu ? "visible" : "hidden")};
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  will-change: opacity, visibility, height, width;
  overflow: hidden;
  padding-right: 1rem;

  & div {
    /* background: #131517fc; */
  }
  ${media.tabletLand`
    padding-right: 0;
  `}
  ${media.mobileLand`
    padding-right: 0;
  `}
  ${media.mobile`
    padding-right: 0;
  `}
`;

const faded = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
`;

const MenuLinks = styled.ul`
  list-style: none;
  font-size: 3rem;
  text-align: center;
  color: #fff;
  padding: 0;
  /* background: #131517fc; */

  & a {
    /* background: linear-gradient(to bottom, var(--gold) 0%, var(--gold) 100%); */
    background: ${props =>
      `linear-gradient(to bottom, ${props.theme.secondary} 0%, ${props.theme.secondary} 100%)`};
    background-position: 0px 55%;
    background-repeat: repeat-x;
    background-size: 14px 0px;
    color: #fff;
    text-decoration: none;
    transition: background-size 0.3s;
    padding: 0 1rem;

    &:hover {
      background-size: 4px 50px;
      opacity: 1;
    }
  }

  & li {
    cursor: pointer;
    margin: 3rem;
    opacity: 0;
    transition: opacity 0.3s;
    animation: ${props =>
      props.isMenu
        ? css`
            ${faded} 0.5s ease forwards
          `
        : "none"};
    animation-delay: ${props => (props.isMenu ? "0.30s" : "none")};
    ${media.tabletLand`
      margin: 2rem;
    `}
    ${media.mobileLand`
      margin: 3rem 2rem;
    `}
  }

  & li:nth-of-type(2) {
    animation-delay: ${props => (props.isMenu ? "0.35s" : "none")};
  }

  & li:nth-of-type(3) {
    animation-delay: ${props => (props.isMenu ? "0.40s" : "none")};
  }

  & li:nth-of-type(4) {
    animation-delay: ${props => (props.isMenu ? "0.45s" : "none")};
  }
  ${media.laptopLarge`
    font-size: 2rem;
  `}
  ${media.tabletLand`
    font-size: 3vw;
  `}
  ${media.mobile`
    font-size: 2rem;
  `}
`;

const LinkItem = styled.li`
  margin-left: 4rem;
`;

const MenuWrapper = styled.div``;

const MenuItem1 = styled.div`
  width: 100%;
  /* flex: 1; */
`;

const MenuItem2 = styled.div`
  width: 100%;
  width: 100%;
  text-align: center;
  color: #fff;
  display: none;
  font-size: 1.5rem;
  & :hover {
    cursor: pointer;
  }
  & svg {
    margin: 0 1rem;
  }
  ${media.tabletLand`
    display: block;
  `}
`;

const MenuItem3 = styled.div`
  /* color: #545454; */
  color: ${props => props.theme.text};
  text-align: center;
  font-size: 0.7rem;
  margin-bottom: 1rem;
  flex: 0;
  & span {
    color: red;
  }
  ${media.laptopLarge`
    display: block;
  `}
  ${media.tabletLand`
    display: block;
  `}
`;

export default NavLinks;
