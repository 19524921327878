import React from "react";
import styled from "styled-components";

const Icon = () => {
  return (
    <BrandIcon
      width="43"
      height="50"
      viewBox="0 0 43 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="11.5714" width="15.7143" height="29" fill="white" />
      <ellipse cx="27.2857" cy="14.5" rx="15.7143" ry="14.5" fill="white" />
      <line x1="8.5" y1="5.96782e-08" x2="8.5" y2="50" stroke="white" />
      <line x1="4.5" y1="5.96782e-08" x2="4.5" y2="50" stroke="white" />
      <line x1="0.500001" y1="9.81533e-08" x2="0.5" y2="50" stroke="white" />
    </BrandIcon>
  );
};

const BrandIcon = styled.svg`
  width: 60%;
`;
export default Icon;
