import c1 from './c1.png';
import c2 from './c2.png';
import c3 from './c3.png';

// export default [c1, c2, c3];
export default [
  { original: c1, thumbnail: c1 },
  { original: c2, thumbnail: c2 },
  { original: c3, thumbnail: c3 }
];
